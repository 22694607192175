import Head from "next/head"
import React from "react";
import styles from "../styles/Home.module.css";
import Image from "next/image";
import classNames from "classnames";

import logo from "../public/assets/images/logo.png";
import banner from '../public/assets/images/banner.jpg'
import a1 from '../public/assets/images/a1.png'
import a2 from '../public/assets/images/a2.png'
import a3 from '../public/assets/images/a3.png'
import l1 from '../public/assets/images/l1.png'
import l2 from '../public/assets/images/l2.png'
import l3 from '../public/assets/images/l3.png'
import l4 from '../public/assets/images/l4.png'
import l5 from '../public/assets/images/l5.png'
import Link from "next/link";


export default function Home() {

  return (
    <React.Fragment>
      <Head>
        <title>瑞致博远健康科技（北京）有限公司 - 官网</title>
        <meta name="robots" content="index,follow" />
        <meta name="renderer" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="chrome=1" />
        {/* Open Graph 协议 */}
        <meta property="og:locale" content="zh_CN" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="瑞致博远健康科技（北京）有限公司官网" />
        <meta property="og:url" content="www.hmclinic.cn/" />
        <meta property="og:image" content="https://hjblm-platform.oss-cn-beijing.aliyuncs.com/clinic/favicon.png" />
        <meta property="og:image:alt" content="瑞致博远健康科技（北京）有限公司官网" />
        <meta
          property="og:description"
          content="瑞致博远健康科技（北京）有限公司多年来围绕医药行业开展工作，并关注我国疑难重症诊疗和保障工作，支持推动我国医药行业发展的公益事业，多次参与帮助患者及家庭的公益活动。"
        />
        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://hjblm-platform.oss-cn-beijing.aliyuncs.com/clinic/favicon.png" />
        <meta property="og:width" content="64" />
        <meta property="og:height" content="64" />
        <meta property="og:image:width" content="64" />
        <meta property="og:image:height" content="64" />
        <meta name="twitter:image:alt" content="瑞致博远健康科技（北京）有限公司官网" />
        <meta property="og:title" content="瑞致博远健康科技（北京）有限公司官网" />
        <meta
          name="keywords"
          content="服务罕见病患者、诊断、治疗、康复、用药可及性"
        />
        <meta
          name="description"
          content="瑞致博远健康科技（北京）有限公司多年来围绕医药行业开展工作，并关注我国疑难重症诊疗和保障工作，支持推动我国医药行业发展的公益事业，多次参与帮助患者及家庭的公益活动。"
        />
      </Head>
      <header className={styles.header}>
        <h1 className="visually_hidden" tabIndex={0}>瑞致博远健康科技（北京）有限公司</h1>
        <div className={styles.header__logo}>
          <Image src={logo} alt="瑞致博远健康科技（北京）有限公司" className={styles.logo} width={247} height={42} />
        </div>
        <div className={styles.header__banner}>
          <section className={styles.header__info}>
            <h2>瑞致博远健康科技（北京）有限公司</h2>
            <p>
              瑞致博远健康科技（北京）有限公司多年来围绕医药行业开展工作，并关注我国疑难重症诊疗和保障工作，支持推动我国医药行业发展的公益事业，多次参与帮助患者及家庭的公益活动。
            </p>
          </section>
          <Image
            src={banner}
            alt="Banner图"
            sizes="(max-width: 640px) 100vw, (max-width: 768px) 100vw, (max-width: 1024px) 100vw, 100vw"
            layout="responsive"
            height={609}
            className={styles.banner}
          />
        </div>
      </header>
      <main>
        <section className={styles.wrapper}>
          <div className={styles.wrapper__inner}>
            <div className={classNames(styles.item, styles.leftImageWrapper)}>
              <Image src={a2} alt="行业领先的医疗方案" className={styles.image} width={448} height={298} />
              <div className={styles.item__inner}>
                <h2>01</h2>
                <p><span className={styles.blue}>行业领先</span>的医疗方案。</p>
              </div>
            </div>

            <div className={classNames(styles.item, styles.leftImageWrapper)}>
              <div className={styles.item__inner}>
                <h2>02</h2>
                <p>
                  <span className={styles.blue}>300余家</span>医疗机构、多个国家级和区域级平台的运营实施经验
                </p>
              </div>
              <Image src={a3} alt="行业领先的医疗方案" className={styles.image} width={448} height={298} />
            </div>

            <div className={classNames(styles.item, styles.leftImageWrapper)}>
              <Image src={a1} alt="行业领先的医疗方案" className={styles.image} width={448} height={298} />
              <div className={styles.item__inner}>
                <h2>03</h2>
                <p>
                  为医疗行业提供创新解决方案，助力行业<span className={styles.blue}>数智化转型和高质量发展</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.white_wrapper}>
          <h2>我们的服务</h2>
          <div className={styles.images_list}>
            <div className={styles.images_item}>
              <Image src={l1} alt="技术服务" className={styles.image} width={50} height={50} />
              <h3>技术服务</h3>
            </div>
            <div className={styles.images_item}>
              <Image src={l2} alt="会议服务" className={styles.image} width={50} height={50} />
              <h3>会议服务</h3>
            </div>
            <div className={styles.images_item}>
              <Image src={l3} alt="医学研究" className={styles.image} width={50} height={50} />
              <h3>医学研究</h3>
            </div>
            <div className={styles.images_item}>
              <Image src={l4} alt="健康管理" className={styles.image} width={50} height={50} />
              <h3>健康管理</h3>
            </div>
            <div className={styles.images_item}>
              <Image src={l5} alt="承办线上/线下活动" className={styles.image} width={50} height={50} />
              <h3>承办线上/线下活动</h3>
            </div>
          </div>
        </section>
      </main>
      <footer className={styles.footer}>
        <h3>瑞致博远健康科技(北京)有限公司</h3>
        <div className={styles.link_wrap}>
          <Link className={styles.link_item} href="https://beian.miit.gov.cn/" target="_blank">
            京ICP备2023005536号-1
          </Link>
          <h4 className={classNames(styles.link_item, styles.link_ml)}>互联网药品信息服务资格证书(京)-经营性-2024-0312</h4>
        </div>

      </footer>
    </React.Fragment>
  );
}